<template>
    <div :class="'c-card c-installation' + (isSelected(index, card.id, card.checked) ? ' is-checked' : '')">
        <label class="custom-control-label" :for="card.id">
            <input type="radio" class="custom-control-input" :id="card.id" name="card" :value="card.name" :checked="isSelected(index, card.id, card.checked)" @change="onChange($event, card)">
            <span class="custom-control-name">{{ card.name }}</span>
        </label>

        <span class="c-installation__subtitle">{{ card.subtitle }}</span>

        <router-link :to="'/detail/' + index + '/' + card.id" class="c-card__link">
            <svg class="c-card__link-svg" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                <path class="c-card__link-bg" d="M7.5 0A7.5 7.5 0 1 1 0 7.5 7.5 7.5 0 0 1 7.5 0z"></path>
                <path class="c-card__link-border" d="M7.5 1A6.507 6.507 0 0 0 1 7.5C1 11.084 3.916 14 7.5 14S14 11.084 14 7.5 11.084 1 7.5 1m0-1a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15z"></path>
                <text class="c-card__link-text" transform="translate(6 11)" font-size="10" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="0">i</tspan></text>
            </svg>
            <span class="u-hidden-lg-down">Lees meer</span>
        </router-link>

        <ul class="c-installation__options">
            <li class="c-installation__option" v-for="option in card.options" :key="option.name">
                <span :class="!option.connected ? 'c-installation__option--disabled' : ''">{{ option.name }}</span>

                <!--<svg v-if="option.connected" xmlns="http://www.w3.org/2000/svg" width="16.087" height="11.42" viewBox="0 0 16.087 11.42">
                    <path d="M724.605,374.266l5.081,5.081,9.006-9.006" transform="translate(-723.605 -369.342)" fill="none" stroke="#16c131" stroke-linecap="round" stroke-width="2"></path>
                </svg>

                <svg v-if="!option.connected" xmlns="http://www.w3.org/2000/svg" width="11.107" height="11.108" viewBox="0 0 11.107 11.108">
                    <g fill="none" stroke="#b1051d" stroke-linecap="round" stroke-width="2">
                        <path d="M10.107 1.001L1 10.109"></path>
                        <path d="M1 1l9.107 9.107"></path>
                    </g>
                </svg>-->
            </li>
        </ul>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'CardInstallation',
        props: {
            card: {
                type: Object,
                required: true,
            },
            index: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapGetters('summary', {
                area: 'getSurfaceArea',
                isSelected: 'isSelected',
            }),
        },
        methods: {
            onChange (event, payload) {
                // eslint-disable-next-line
                payload.quantity = this.area;

                // add the summary
                this.$store.dispatch('summary/addToSummary', {
                    payload,
                    index: this.index,
                    type: this.type,
                });

                // update tab
                this.$store.dispatch('tabs/updateTab', {
                    index: this.index,
                    type: this.type,
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/essentials";

    .c-installation {
        border-width: rem(2);
        padding: rem(30);

        @include media-breakpoint-up(lg) {
            padding: rem(40);
        }

        &__subtitle {
            font-weight: $font-weight-bold;
            padding-left: rem(40);
        }

        &__options {
            margin: 0;
            padding: rem(30) 0 0 0;
            list-style: none;

            @include media-breakpoint-up(lg) {
                padding-top: rem(50);
            }
        }

        &__option {
            align-items: center;
            display: flex;
            justify-content: space-between;

            /*&--disabled {
                opacity: 0.5;
            }*/

            & + & {
                border-top: rem(1) solid $gray-light;
                padding-top: rem(10);
                margin-top: rem(10);
            }
        }

        // IE

        &:nth-child(1) { -ms-grid-column: 1; }
        &:nth-child(2) { -ms-grid-column: 2; }
        &:nth-child(3) { -ms-grid-column: 3; }
    }

    .custom-control-name:before {
        background-color: $white;
    }
</style>
