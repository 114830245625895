<template>
    <div class="o-container">
        <app-intro>
            <h1>Kies uw installatie</h1>
            <h3>Welk installatiepakket is benodigd of gewenst?</h3>
        </app-intro>

        <loading v-bind:nodes="nodes">Bezig met laden van de installaties...</loading>

        <transition-group class="c-installations" name="fade" tag="section">
            <card-installation v-bind:card="node" :type="type" :index="index" v-for="node in nodes" :key="node.id"></card-installation>
        </transition-group>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import AppIntro from '../components/app/AppIntro.vue';
    import Loading from '../components/Loading.vue';
    import CardInstallation from '../components/cards/CardInstallation.vue';
    import setStartingValues from '../mixins/setStartingValues';

    export default {
        mixins: [setStartingValues],
        components: {
            CardInstallation,
            Loading,
            AppIntro,
        },
        data () {
            return {
                type: 'radio',
                index: 'installation',
            };
        },
        computed: {
            ...mapState({
                nodes: (state) => state.installations.all,
                configuration: (state) => state.configuration,
            }),
            ...mapGetters('summary', {
                area: 'getSurfaceArea',
            }),
        },
        async mounted () {
            const { configuration } = this.$store.state;
            this.$store.commit('tabs/setCurrentTab', this.$route.name);
            await this.$store.dispatch('installations/getAll', configuration);
            this.setStartingValues();
        },
        beforeRouteEnter (to, from, next) {
            next((vm) => {
                const { tabs } = vm.$store.state;
                const isDisabled = tabs.tabs[tabs.currentTab].disabled;

                if (isDisabled)
                {
                    const index = 'installation';
                    const nextTabs = vm.$store.getters['tabs/getNextTabsFromIndex'](index);
                    vm.$store.dispatch('tabs/disableTabs', nextTabs);
                    vm.$store.dispatch('summary/resetSummary', [index]);
                    next('/');
                }
                else
                {
                    next();
                }
            });
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .c-installations {
        display: grid;
        grid-gap: rem(20);

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
</style>
